<script setup>
import { HaButton, HaCheckbox, HaFormField } from '@ha/components'
import { ValidationProvider } from 'vee-validate'
import useTracking from '@/composables/useTracking'
import useStoreData from '@/composables/useStoreData'
import useLegalCheckbox from './useLegalCheckbox'

const props = defineProps({
  isTipable: {
    type: Boolean,
    default: true
  }
})

const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods

const { legalState, setLegalCheckboxes } = useLegalCheckbox()
const { form } = useStoreData()

setLegalCheckboxes()

const trackFirstClick = () => {
  onFirstClick('First Interaction Campaign', {
    step: 0,
    step_name: 'CGU'
  })
}
</script>

<template>
  <div class="LegalCheckboxes">
    <ValidationProvider
      v-if="form && form.conditionsAndTermsFile"
      v-slot="{ errors, failedRules }"
      name="cgvconsent"
      :rules="{ required: { allowFalse: false } }"
    >
      <HaFormField :is-valid="!errors.length">
        <HaCheckbox
          v-model="legalState.cgvConsent"
          data-test="checkbox-cgv"
          @click="trackFirstClick"
        >
          {{ $t('campaign.step.summary.acceptDocument') }}
          <ha-button
            :href="form.conditionsAndTermsFile.publicUrl"
            target="_blank"
            rel="noopener noreferrer"
            variant="link"
            class="SummaryValidation--CGV"
            :data-ux="`Forms_${metadata.type}_StepSummary_AcceptConditions`"
            @click="trackFirstClick"
          >
            {{ form.conditionsAndTermsFile.fileName }}
          </ha-button>
          *
        </HaCheckbox>

        <template #error>
          <template v-if="failedRules.required">
            {{ $t('forms.requiredField') }}
          </template>
          <template v-else>
            {{ errors[0] }}
          </template>
        </template>
      </HaFormField>
    </ValidationProvider>
    <ValidationProvider
      v-if="legalState.tipConsent !== null && props.isTipable"
      v-slot="{ errors, failedRules }"
      name="tip-consent"
      :rules="{ required: { allowFalse: false } }"
    >
      <HaFormField :is-valid="!errors.length">
        <HaCheckbox
          v-model="legalState.tipConsent"
          data-test="checkbox-contribution"
          @click="trackFirstClick"
        >
          {{ $t('campaign.step.summary.understandContribution') }}
        </HaCheckbox>

        <template #error>
          <template v-if="failedRules.required">
            {{ $t('forms.requiredField') }}
          </template>
          <template v-else>
            {{ errors[0] }}
          </template>
        </template>
      </HaFormField>
    </ValidationProvider>
    <ValidationProvider
      v-if="legalState.gdrpConsent !== null"
      v-slot="{ errors, failedRules }"
      name="cgu-consent"
      :rules="{ required: { allowFalse: false } }"
    >
      <HaFormField :is-valid="!errors.length">
        <HaCheckbox
          v-model="legalState.gdrpConsent"
          data-test="checkbox-cgu"
          @click="trackFirstClick"
        >
          {{ $t('campaign.step.summary.acceptGdpr_1') }}
          <ha-button
            href="/cgu-utilisateur"
            target="_blank"
            variant="link"
            :data-ux="`Forms_${metadata.type}_StepSummary_AcceptTS`"
            @click="trackFirstClick"
          >
            {{ $t('campaign.step.summary.acceptGdprLink_1') }}
          </ha-button>
          {{ $t('campaign.step.summary.acceptGdpr_2') }}
          <ha-button
            href="/confidentialite"
            target="_blank"
            variant="link"
            :data-ux="`Forms_${metadata.type}_StepSummary_AcceptPrivacyPolicy`"
            @click="trackFirstClick"
          >
            {{ $t('campaign.step.summary.acceptGdprLink_2') }}
          </ha-button>
          . *
        </HaCheckbox>
        <template #error>
          <template v-if="failedRules.required">
            {{ $t('forms.requiredField') }}
          </template>
          <template v-else>
            {{ errors[0] }}
          </template>
        </template>
      </HaFormField>
    </ValidationProvider>

    <p class="LegalCheckboxes--Anotation">
      {{ $t('campaign.requiredFields') }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.LegalCheckboxes {
  .HaFormField {
    margin: $ha-spacing $ha-spacing-small;
  }

  &--Anotation {
    margin: $ha-spacing-medium 0 0 $ha-spacing * 1.25;
    color: var(--ha-color-text-light);
    font-weight: $ha-font-weight-bold;
    font-size: $ha-unit * 1.5;
  }

  a {
    display: inline;
  }
}
</style>

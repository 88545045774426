<script setup>
import { computed } from 'vue'
import { ValidationProvider } from 'vee-validate'
import { getValidationProviderRules } from '@/helpers/formUtils'
import FormField from '@/components/globals/form-fields/FormField.vue'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useStoreData from '@/composables/useStoreData'
import useTracking from '@/composables/useTracking'

const props = defineProps({
  selectedTier: Object
})

const { customFields } = usePayerPersonalInformations()
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods
const { form, organization } = useStoreData()

const tierCustomFields = computed(() => props.selectedTier?.customFields || [])

const trackingData = computed(() => {
  return {
    form_id: form.value.formId,
    form_slug: form.value.formSlug,
    form_type: form.value.formType,
    step: 0,
    step_name: 'Coordonnées',
    organization_slug: organization.value.organizationSlug
  }
})
</script>

<template>
  <div>
    <ValidationProvider
      v-for="(field, index) in tierCustomFields"
      :key="`${field.id}-field-${index}`"
      v-slot="{ errors, valid, validated }"
      :rules="getValidationProviderRules(field)"
      :name="`${index}-${field.id}`"
      slim
    >
      <FormField
        :id="`field-id-${index}-${index}-${field.id}`"
        v-model="customFields[field.id]"
        :label="field.isRequired ? `${field.label} *` : field.label"
        :name="`${index}-${field.id}`"
        :type="field.type"
        :values="field.values"
        :is-valid="validated ? valid : null"
        :errors="errors"
        disable-file-upload
        data-test="additional-info"
        @click="onFirstClick('First Interaction Campaign', trackingData)"
      />
    </ValidationProvider>
  </div>
</template>

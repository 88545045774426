<script setup>
import { ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import CustomFieldsPersonalInformations from '@/components/payers/partials/CustomFieldsPersonalInformations.vue'
import CompanyPersonalInformations from '@/components/payers/partials/CompanyPersonalInformations.vue'
import AddressPersonalInformations from '@/components/payers/partials/AddressPersonalInformations.vue'
import UserPersonalInformations from '@/components/payers/partials/UserPersonalInformations.vue'
import useFormType from '@/composables/useFormType'

// selectedTier only for Sale / Donation / checkout
defineProps({
  selectedTier: {
    type: Object
  },
  isAddressNeeded: {
    type: Boolean
  }
})
const { setPayerPersonalInformations, personalInformations } = usePayerPersonalInformations()

const payerForm = ref(null)
const { isCheckout } = useFormType()

// when user logout we want errors on fields
const forceValidate = () => {
  payerForm.value.validate()
}

setPayerPersonalInformations()
</script>

<template>
  <div class="Block-Personal-Informations">
    <ValidationObserver ref="payerForm" tag="div">
      <CompanyPersonalInformations />
      <UserPersonalInformations @logout="forceValidate"/>
      <AddressPersonalInformations v-if="isAddressNeeded || personalInformations.isCompany" />
      <CustomFieldsPersonalInformations v-if="selectedTier" :selected-tier="selectedTier" />
      <p v-if="!isCheckout" class="Block-Personal-Informations--Required">
        {{ $t('campaign.requiredFields') }}
      </p>
    </ValidationObserver>
  </div>
</template>

<style lang="scss">
.Block-Personal-Informations {
  text-align: left;

  .Columns {
    .HaFormField,
    .Field {
      width: 100%;
      padding: 0 $ha-spacing-small;
    }
  }

  .HaFormField,
  .Field {
    width: 100%;
    margin-bottom: $ha-spacing-medium;
    padding: 0;

    .HaNumericInput {
      width: inherit;
    }
  }

  &--Content {
    height: 100%;
  }

  &--Loader {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    min-height: $ha-unit * 25; // 200px
  }

  &--Checkbox {
    margin-bottom: $ha-spacing-medium;
  }

  &--Login {
    margin-bottom: $ha-spacing-medium;
  }

  &--Email {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;

    &--Edit {
      height: $ha-unit * 5.25; // 42px (input height)
      margin-bottom: $ha-spacing-medium;
    }
  }

  &--BirthDate {
    @include mediaQuery(600) {
      max-width: $ha-unit * 18.5; // 148px
    }
  }

  &--Required {
    display: block;
    margin-top: $ha-spacing-medium;
    color: var(--ha-color-text);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-unit * 1.5;
  }
}
</style>

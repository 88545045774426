import { useContext } from '@nuxtjs/composition-api'
import { Ref, computed } from 'vue'

export default (mail: Ref<string>) => {
  const { i18n } = useContext()
  const abnormalEmailWarning = i18n.t('validators.abnormalEmail')
  const abnormalCharacters = ['ù', 'é', 'è', 'ç', 'à', 'ê', 'ë', '$', '*', '%', ':', '!', ',', '?']
  const abnormalExtensions = ['.col', '.cok', '.cop', '.ft', '.fe', 'gmail.fr']

  const hasAbnormalValues = computed(() => {
    return (
      !!mail.value &&
      (abnormalCharacters.some(character => mail.value.includes(character)) ||
        abnormalExtensions.some(extension => mail.value.endsWith(extension)))
    )
  })

  const emailFieldValidityState = (isEnabled: boolean, isValid: boolean) => {
    if (!isEnabled) return null
    return !hasAbnormalValues.value && isValid
  }

  return {
    abnormalEmailWarning,
    hasAbnormalValues,
    emailFieldValidityState
  }
}

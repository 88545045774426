<script setup>
import { ref, computed } from 'vue'
import { ValidationProvider } from 'vee-validate'
import { HaCheckbox, HaAutoComplete, HaFormField, HaInput } from '@ha/components'
import { functions } from '@ha/helpers'
import ButtonLogin from '@/components/payers/partials/ButtonLogin.vue'
import companyLegalStatuses from '@/company_statuses'
import useFormType from '@/composables/useFormType'
import useStoreData from '@/composables/useStoreData'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useTracking from '@/composables/useTracking'
import { onMounted } from '@nuxtjs/composition-api'

const { isDonation, isCheckout } = useFormType()
const { user, form, organization, cart } = useStoreData()
const {
  personalInformations,
  updatePersonalInformations,
  displayCheckboxIsOrganism,
  allowOnlyOrganism
} = usePayerPersonalInformations()
const { trackingMethods } = useTracking()
const { onFirstClick } = trackingMethods
const companyLegalStatusInput = ref(null)

const hasUser = computed(() => !!user.value?.userId)

const showAddressForRF = computed(() => {
  return (
    organization.value.fiscalReceiptEligibility &&
    functions.sumBy(cart.value?.paymentTerms, 'taxReceiptEligible') > 0
  )
})

const isLegalStatus = computed(
  () =>
    !!personalInformations.value?.companyLegalStatus ||
    personalInformations.value?.companyLegalStatus === 0
)
const companyStatus = computed(() => {
  if (!isLegalStatus.value) {
    return ''
  }
  const userLegalStatus = personalInformations.value.companyLegalStatus.toString()
  const legalStatus = companyLegalStatuses.find(elt => elt.value === userLegalStatus)?.text || ''
  return legalStatus
})

const onSelectStatus = newCompanyLegalStatus => {
  companyLegalStatusInput.value.syncValue(newCompanyLegalStatus)
  companyLegalStatusInput.value.validate()
  updatePersonalInformations({ companyLegalStatus: newCompanyLegalStatus })
}

const trackFirstClick = () => {
  onFirstClick('First Interaction Campaign', {
    step: 0,
    step_name: 'Coordonnées'
  })
}

const validateCompanyLegalStatus = () => {
  companyLegalStatusInput.value.validate()
}

onMounted(() => {
  // autocomplete "Forme juridique" need forced validation if already has a value
  if (
    personalInformations.value.companyLegalStatus &&
    (showAddressForRF.value || isDonation.value)
  ) {
    companyLegalStatusInput.value.syncValue(parseInt(personalInformations.value.companyLegalStatus))
    companyLegalStatusInput.value.validate()
  }

  if (!displayCheckboxIsOrganism.value)
    personalInformations.value.isCompany = allowOnlyOrganism.value
})
</script>

<template>
  <div>
    <HaCheckbox
      v-if="displayCheckboxIsOrganism"
      id="company-switch"
      v-model="personalInformations.isCompany"
      name="company-switch"
      class="Block-Personal-Informations--Checkbox"
      data-testid="checkbox-company-switch"
      @click="trackFirstClick"
    >
      {{ $t('campaign.step.payer.payAsOrg') }}
    </HaCheckbox>
    <ButtonLogin v-if="!hasUser && !isCheckout" />
    <template v-if="personalInformations.isCompany === true">
      <ValidationProvider
        v-slot="{ errors, valid, validated }"
        rules="REQUIRED|max_length:150"
        name="companyName"
        slim
      >
        <HaFormField :is-valid="validated ? valid : null">
          <template #label> {{ $t('campaign.field.organism') }} * </template>
          <HaInput
            v-model="personalInformations.companyName"
            :is-valid="validated ? valid : null"
            :max-length="150"
            required
            hide-counter
            data-testid="input-company-name"
            @click="trackFirstClick"
          />
          <template #error>
            {{ errors?.[0] }}
          </template>
        </HaFormField>
      </ValidationProvider>
      <div v-if="isDonation || showAddressForRF" :class="{ Columns: !isDonation }">
        <ValidationProvider
          v-slot="{ errors, valid, validated }"
          rules="REQUIRED|SIREN"
          name="companySiren"
          slim
        >
          <HaFormField :is-valid="validated ? valid : null">
            <template #label> {{ $t('campaign.field.companySiren') }} * </template>
            <HaInput
              v-model="personalInformations.companySiren"
              :is-valid="validated ? valid : null"
              :max-length="9"
              required
              hide-counter
              data-testid="input-siren"
              @click="trackFirstClick"
              @keydown="functions.allowOnlyNumericEntry"
            />
            <template #error>
              {{ errors?.[0] }}
            </template>
          </HaFormField>
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ validated, valid }"
          ref="companyLegalStatusInput"
          name="company-legal-status"
          rules="REQUIRED"
          slim
        >
          <HaFormField :is-valid="validated ? valid : null">
            <template #label> {{ $t('campaign.field.companyLegalStatusId') }}* </template>
            <HaAutoComplete
              name="company-status"
              :is-valid="validated ? valid : null"
              :initial-value="companyStatus"
              show-results-on-click
              :options="companyLegalStatuses"
              data-testid="input-legal-status"
              @select="onSelectStatus($event.value)"
              @click="trackFirstClick"
              @blur="validateCompanyLegalStatus"
            />
            <template #error>
              {{ $t('validators.youMustChooseAStatus') }}
            </template>
          </HaFormField>
        </ValidationProvider>
      </div>
    </template>
  </div>
</template>

import { inject, computed, ref } from 'vue'
import useRouteParams from '@/composables/useRouteParams'

export default (props, emit) => {
  const isWidget = inject('isWidget')
  const { formType } = useRouteParams()

  const lastStoredValue = ref(null) // use to make sure we don't let user leave an empty customField
  const isUsingCustomField = ref(false)
  const orderedTipSuggestions = computed(() =>
    Object.values(props.tipSuggestions).sort((a, b) => {
      return parseFloat(a) > parseFloat(b) ? 1 : -1
    })
  )

  const updateTip = value => {
    emit('update:tip', value)
  }

  const updateLastStoredValue = () => {
    lastStoredValue.value = props.tip
  }

  const setIsUsingCustomField = () => {
    isUsingCustomField.value = !orderedTipSuggestions.value.includes(props.tip)
  }

  const dataUxInput = computed(
    () =>
      `Forms_${formType.value.toUpperCase()}${
        isWidget ? 'Widget' : ''
      }_Modal_ContributionSelection_ContributionAmount`
  )

  updateLastStoredValue()

  return {
    updateTip,
    orderedTipSuggestions,
    lastStoredValue,
    updateLastStoredValue,
    setIsUsingCustomField,
    dataUxInput,
    isUsingCustomField
  }
}

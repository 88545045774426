export default [
  {
    value: '71',
    text: "Administration de l'état"
  },
  {
    value: '92',
    text: 'Association loi 1901 ou assimilé'
  },
  {
    value: '28',
    text: 'Assujetti unique à la TVA'
  },
  {
    value: '29',
    text: 'Autre groupement de droit privé non doté de la personnalité morale'
  },
  {
    value: '99',
    text: 'Autre personne morale de droit privé'
  },
  {
    value: '69',
    text: 'Autre personne morale de droit privé inscrite au registre du commerce et des sociétés'
  },
  {
    value: '74',
    text: 'Autre personne morale de droit public administratif'
  },
  {
    value: '61',
    text: "Caisse d'épargne et de prévoyance"
  },
  {
    value: '72',
    text: 'Collectivité territoriale'
  },
  {
    value: '83',
    text: "Comité d'entreprise"
  },
  {
    value: '10',
    text: 'Entrepreneur individuel'
  },
  {
    value: '73',
    text: 'Etablissement public administratif'
  },
  {
    value: '41',
    text: 'Etablissement public ou régie à caractère industriel ou commercial'
  },
  {
    value: '24',
    text: 'Fiducie '
  },
  {
    value: '93',
    text: 'Fondation'
  },
  {
    value: '62',
    text: "Groupement d'intérêt économique"
  },
  {
    value: '21',
    text: 'Indivision'
  },
  {
    value: '0',
    text: 'Organisme de placement collectif en valeurs mobilières sans personnalité morale'
  },
  {
    value: '85',
    text: 'Organisme de retraite à adhésion non obligatoire'
  },
  {
    value: '81',
    text: 'Organisme gérant un régime de protection sociale à adhésion obligatoire'
  },
  {
    value: '82',
    text: 'Organisme mutualiste'
  },
  {
    value: '84',
    text: 'Organisme professionnel'
  },
  {
    value: '27',
    text: 'Paroisse hors zone concordataire'
  },
  {
    value: '31',
    text: 'Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés)'
  },
  {
    value: '32',
    text: 'Personne morale de droit étranger, non immatriculée au RCS'
  },
  {
    value: '55',
    text: "Société anonyme à conseil d'administration"
  },
  {
    value: '56',
    text: 'Société anonyme à directoire'
  },
  {
    value: '65',
    text: 'Société civile'
  },
  {
    value: '63',
    text: 'Société coopérative agricole'
  },
  {
    value: '51',
    text: 'Société coopérative commerciale particulière'
  },
  {
    value: '22',
    text: 'Société créée de fait'
  },
  {
    value: '64',
    text: "Société d'assurance mutuelle"
  },
  {
    value: '53',
    text: 'Société en commandite'
  },
  {
    value: '52',
    text: 'Société en nom collectif'
  },
  {
    value: '23',
    text: 'Société en participation'
  },
  {
    value: '58',
    text: 'Société européenne '
  },
  {
    value: '57',
    text: 'Société par actions simplifiée'
  },
  {
    value: '54',
    text: 'Société à responsabilité limitée (SARL)'
  },
  {
    value: '91',
    text: 'Syndicat de propriétaires'
  }
]

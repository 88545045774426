<template>
  <div class="Datepicker">
    <ha-input
      :id="id"
      v-model="inputValue"
      :placeholder="placeholder"
      :is-valid="isValid"
      :max-length="10"
      hide-counter
      v-bind="$attrs"
      @keydown="checkEntry"
      @input="$emit('input', $event)"
      @change="$emit('change', $event.target.value)"
    />
  </div>
</template>

<script>
import { HaInput } from '@ha/components'
import { checkNumericEntry } from '@/helpers/formUtils'

export default {
  name: 'Datepicker',
  components: { HaInput },
  inheritAttrs: false,
  props: {
    date: {
      type: Date,
      required: false
    },
    format: {
      type: String,
      required: false
    },
    value: {
      type: [String, Date, Object],
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    isValid: {
      type: Boolean,
      default: true,
      required: false
    },
    id: String,
    name: String
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  methods: {
    async checkEntry(event) {
      checkNumericEntry(event)
      // remove . and , from checkNumericEntry permission
      if (isNaN(event.key) && [',', '.'].includes(event.key)) {
        event.preventDefault()
      }

      if (
        !isNaN(event.key) &&
        (event.target.value.length === 2 || event.target.value.length === 5)
      ) {
        this.inputValue += '/'
      }
    }
  }
}
</script>

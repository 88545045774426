<template>
  <div :class="`Field Field-${type} Column ${size}`">
    <ha-label
      v-if="label !== '' && !['Checkbox', 'Switch'].includes(type)"
      :for-id="id"
      :class="{
        Inline: true,
        Danger: isValid === false || (type === 'File' && errorUpload)
      }"
    >
      <template>
        <slot name="label">
          {{ label }}
        </slot>
        <slot name="tooltip" />
      </template>
      <ha-loader v-if="type === 'File' && isUploading" />
    </ha-label>
    <!-- Single element -->
    <div v-if="isSingleElement" class="FieldWrapper">
      <ha-input
        v-if="type === 'TextInput' || type === 'Phone' || type === 'Zipcode'"
        :id="id"
        :data-test="dataTestid"
        :class="controlClass"
        :name="name"
        :is-valid="isValid"
        :max-length="maxLength || 250"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :hide-counter="hideCounter"
        @input="onInput"
        @change="$emit('change', $event)"
      />
      <ha-numeric-input
        v-if="type === 'Number'"
        class="HaNumericInput"
        :data-test="dataTestid"
        :name="name"
        :value="value"
        :disabled="disabled"
        :is-valid="isValid"
        :can-be-negative="true"
        :max-length="maxLength"
        :hide-counter="hideCounter"
        @input="onInput"
        @change="$emit('change', $event)"
      />
      <ha-select
        v-if="type === 'ChoiceList'"
        :id="id"
        :data-test="dataTestid"
        :model="value"
        :name="name"
        :value="value"
        :placeholder="placeholder"
        :class="controlClass"
        :options="optionValues"
        :is-valid="isValid"
        :disabled="disabled"
        @change="onChangeWithoutInput"
      />
      <ha-checkbox
        v-if="type === 'Checkbox'"
        :id="id"
        :data-test="dataTestid"
        :name="name"
        :is-checked="isChecked"
        :value="!!value"
        :disabled="disabled"
        @change="onChangeWithoutInput"
      >
        <slot name="labelCheckbox">{{ label }}</slot>
      </ha-checkbox>
      <ha-switch
        v-if="type === 'Switch'"
        :id="id"
        :data-test="dataTestid"
        :name="name"
        :is-checked="isChecked"
        :value="!!value"
        :disabled="disabled"
        @change="onChangeWithoutInput"
      >
        <slot name="labelSwitch">{{ label }}</slot>
      </ha-switch>
      <div v-if="type === 'YesNo'" class="Radio--Group">
        <ha-radio
          :data-test="dataTestid"
          :name="name"
          :is-valid="isValid"
          :value="true"
          :model="value"
          @change="onChangeWithoutInput"
        >
          Oui
        </ha-radio>
        <ha-radio
          :data-test="dataTestid"
          :name="name"
          :is-valid="isValid"
          :value="false"
          :model="value"
          @change="onChangeWithoutInput"
        >
          Non
        </ha-radio>
      </div>
      <client-only>
        <Datepicker
          v-if="type === 'Date'"
          :id="id"
          :data-test="dataTestid"
          :value="value"
          :is-valid="isValid"
          :class="controlClass"
          :name="name"
          format="dd/MM/yyyy"
          placeholder="jj/mm/aaaa"
          :disabled="disabled"
          @input="onInput"
          @change="$emit('change', $event)"
        />
      </client-only>
      <ha-text-area
        v-if="type === 'FreeText'"
        :id="id"
        :data-test="dataTestid"
        :value="value ? value : ''"
        :name="name"
        :placeholder="placeholder"
        :max-length="maxLength || 5000"
        :disabled="disabled"
        :hide-counter="hideCounter"
        cols="30"
        rows="10"
        @input="onInput"
        @change="$emit('change', $event)"
      />
      <client-only>
        <template v-if="type === 'File'">
          <ha-file-uploader
            v-model="file"
            :data-test="dataTestid"
            :label="$t('campaign.field.file')"
            :formats="['pdf', 'gif', 'png', 'jpg']"
            max-size="5 Mo"
            accept="image/*,application/pdf"
          />
        </template>
      </client-only>
      <slot />
    </div>
    <!-- Multiple elements -->
    <div v-if="!isSingleElement && type === 'RADIO'" class="FieldWrapper">
      <div v-for="(option, index) in values" :key="`group-${index}`" class="Radio--Group">
        <ha-radio
          v-model="value"
          :data-test="dataTestid"
          :name="name"
          :is-valid="isValid"
          :value="option.value"
          @change="onChangeWithoutInput"
        >
          {{ option.name }}
        </ha-radio>
      </div>
      <slot />
    </div>
    <span v-if="errors && errors.length" class="Field--Error">
      <slot name="errors">
        {{ errors[0] || errorUpload }}
      </slot>
    </span>
  </div>
</template>

<script>
import { has, map, nth, toString } from 'lodash-es'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import {
  HaTextArea,
  HaLabel,
  HaRadio,
  HaCheckbox,
  HaSwitch,
  HaSelect,
  HaInput,
  HaFileUploader,
  HaLoader,
  HaNumericInput
} from '@ha/components'
import Datepicker from '@/components/globals/form-fields/Datepicker.vue'

export default {
  name: 'FormField',
  components: {
    HaTextArea,
    HaLabel,
    HaRadio,
    HaSelect,
    HaCheckbox,
    HaSwitch,
    HaInput,
    Datepicker,
    HaFileUploader,
    HaLoader,
    HaNumericInput
  },
  inject: ['$notifications'],
  props: {
    // Only used in case of a file upload (which does not go through the store)
    cartId: String,
    id: {
      type: String,
      required: true
    },
    dataTestid: String,
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: String,
    controlClass: String,
    size: {
      type: String,
      default: '',
      required: false
    },
    validation: String,
    isValid: {
      type: Boolean,
      default: null,
      required: false
    },
    isChecked: Function,
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: null,
      required: false
    },
    values: {
      type: null,
      required: false
    },
    errors: {
      type: [Object, Array],
      required: false
    },
    maxLength: Number,
    hideCounter: {
      type: Boolean,
      default: true
    },
    disableFileUpload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionValues: [],
      file: null,
      errorUpload: null,
      isUploading: false
    }
  },
  computed: {
    isSingleElement() {
      // radio, checkboxes will generate multiple elements from a single payload
      return this.type !== 'RADIO'
    },
    faFileUpload() {
      return faFileUpload
    }
  },
  watch: {
    file() {
      if (this.file && !this.isUploading) {
        if (this.disableFileUpload) {
          this.$emit('input', this.file)
          this.$emit('change', this.file)
          return
        }

        this.isUploading = true
        this.errorUpload = null
        this.$store
          .dispatch('carts/uploadFile', [this.cartId, this.file])
          .then(file => {
            this.$emit('input', file)
            this.$emit('change', this.file)
          })
          .catch(error => {
            const status = error.response?.status || error?.statusCode
            this.errorUpload = this.$t('error.code.' + status + '.message')

            this.$notifications.push(
              {
                type: 'danger',
                title: this.$t('error.code.' + status + '.title'),
                body: this.$t('error.code.' + status + '.message'),
                timeout: 5000
              },
              error
            )

            this.file = null
          })
          .finally(() => {
            this.isUploading = false
          })
      }
    }
  },
  created() {
    if (this.type === 'ChoiceList') {
      this.optionValues = this.prefixOptions()
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onChangeWithoutInput(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    isValuesSelectFormatted() {
      const testValue = nth(this.values, 0)
      return has(testValue, 'text') && has(testValue, 'value')
    },
    prefixOptions() {
      const shouldPrefix = !this.isValuesSelectFormatted()
      const base = [
        {
          text: 'Choisir',
          value: null
        }
      ]

      // API expect key to be the same as values
      if (shouldPrefix) {
        return [
          ...base,
          ...map(this.values, text => ({
            text,
            value: toString(text)
          }))
        ]
      }

      return [...this.values]
    }
  }
}
</script>

<style lang="scss">
.Field {
  position: relative;
  padding-bottom: $ha-spacing-medium;

  .QuantitySelector {
    .Input--Wrapper {
      max-width: 100%;
    }
  }

  &.Field-YesNo {
    > .FieldWrapper {
      order: 1;

      .Radio--Group {
        margin-top: $ha-spacing-tiny;

        .HaRadio + .HaRadio {
          margin-top: $ha-spacing-tiny;
        }
      }
    }

    > .Label {
      order: 2;
      padding: 0;
    }
  }

  &.inline {
    display: flex;
  }

  > .Label {
    padding-bottom: $ha-spacing-tiny;

    &.Danger {
      color: var(--ha-color-danger);
    }

    > span {
      font-weight: $ha-font-weight-regular;
      font-size: $ha-font-size-small;
    }
  }

  &--Error {
    display: block;
    margin-top: $ha-spacing-mini;
    color: var(--ha-color-danger);
    font-weight: $ha-font-weight-semibold;
    font-size: $ha-font-size-tiny;
  }

  .HaNumericInput {
    width: $ha-unit * 16; // 128px
  }
}

.Field-File {
  .FieldWrapper {
    display: inline-flex;
    width: 100%;

    .HaFileUploader {
      width: 100%;
    }
  }
}
</style>
